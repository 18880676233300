































import { Component, Vue } from "vue-property-decorator";
import { readAdminUsers } from "@/store/admin/getters";
import { dispatchGetUsers } from "@/store/admin/actions";

@Component
export default class AdminUsers extends Vue {
  public headers = [
    {
      text: "Name",
      sortable: true,
      value: "name",
      align: "left",
    },
    {
      text: "Email",
      sortable: true,
      value: "email",
      align: "left",
    },
    {
      text: "Full Name",
      sortable: true,
      value: "full_name",
      align: "left",
    },
    {
      text: "Is Active",
      sortable: true,
      value: "isActive",
      align: "left",
    },
    {
      text: "Is Superuser",
      sortable: true,
      value: "isSuperuser",
      align: "left",
    },
    {
      text: "Actions",
      value: "id",
    },
  ];
  get users() {
    return readAdminUsers(this.$store);
  }

  public async mounted() {
    await dispatchGetUsers(this.$store);
  }
}
